import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { useQueryParams } from '@web/atomic/obj.custom-hooks/query-params';
import IndexLayout from '@lp-root/src/components/org.layout/layout.component';
import { AssesmentReport } from '@lp-root/src/modules/assesment/assesment-report.component';
import { PageProps } from '@lp-src/utils/local-types';
import { Col, Grid, H1, Row, Separator } from '@web/atomic';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';
import CleanLayout from '../components/org.layout/clean-layout.component';
import { SleepProblemAge, SleepProblemCause } from '../data/quiz-typeform.model';

interface ResultsQuizzPageQueryParams {
  age?: SleepProblemAge;
  cause: SleepProblemCause;
  company?: string;
}

const ResultsQuizzPage: React.FunctionComponent<PageProps> = (props) => {
  const queryParams = useQueryParams<ResultsQuizzPageQueryParams>();

  const assesment = {
    age: Object.values(SleepProblemAge).includes(queryParams.age) ? queryParams.age : null,
    cause: Object.values(SleepProblemCause).includes(queryParams.cause) ? queryParams.cause : null,
  };

  return (
    <CleanLayout>
      <SEO
        robots={'noindex'}
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Resultados do questionário de insônia',
          description: 'Entenda sua insônia e confira uma dica personalizada que preparamos pra você',
        }}
      />
      <Grid>
        <Row center={'xs'} mt>
          <Col xs={12} sm={8}>
            <H1>Resultados do Questionário </H1>
            <AssesmentReport
              assesment={assesment}
              showSeeMore={false}
              hideHowWeCanHelpSection={true}
              cta={queryParams.company && { external: true, href: `/${queryParams.company}` }}
            />
          </Col>
        </Row>
        <Separator />
      </Grid>
    </CleanLayout>
  );
};

export default ResultsQuizzPage;

export const query = graphql`
  query ResultsQuizzQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
